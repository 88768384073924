import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row g-0 w-100 align-items-stretch justify-content-center" }
const _hoisted_2 = { class: "card h-100 mx-5 w-75" }
const _hoisted_3 = { class: "card-body border-top" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PrescriberProjectComponent = _resolveComponent("PrescriberProjectComponent")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_PrescriberProjectComponent, { mode: "create" })
      ])
    ])
  ]))
}